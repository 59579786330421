<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0">
							{{ translate('type') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('notification') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('dispatch_time') }}
						</th>
						<th class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index"
						class="text-nowrap">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.type) }}
						</td>
						<td class="align-middle">
							<template v-if="item.attributes.type === 'sms'">
								{{ item.attributes.text }}
								<template v-if="item.attributes.image_url">
									<br>
									<a
										v-for="image in item.attributes.image_url"
										:key="image"
										class="mr-1"
										target="_blank"
										:href="image">
										<img
											class="card-img"
											:src="image"
											:style="{'max-width': '160px', 'max-height': '150px'}">
									</a>
								</template>
							</template>
							<template v-else-if="item.attributes.type === 'push'">
								<b>{{ item.attributes.title }}</b>
								<br>
								{{ item.attributes.text }}
							</template>
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.created_at.date).format(dateFormat) }}
						</td>
						<td class="align-middle text-center">
							<a
								v-for="url in item.attributes.url"
								:key="url"
								v-b-tooltip.hover
								:title="translate('view_content')"
								variant="primary"
								class="btn btn-xs mr-1 bg-primary-alt"
								target="_blank"
								:href="url">
								<i class="fa fa-eye" />
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { MMMDYHMS_FORMAT } from '@/settings/Dates';
import { Grids, Notifications as NotificationsMessage } from '@/translations';
import Notifications from '@/util/Notifications';

export default {
	name: 'MobileNotifications',
	messages: [Grids, NotificationsMessage],
	data() {
		return {
			notifications: new Notifications(),
			markNotifications: new Notifications(),
			dateFormat: MMMDYHMS_FORMAT,
		};
	},
	computed: {
		loading() {
			return !!this.notifications.data.loading;
		},
		pagination() {
			return this.notifications.data.pagination;
		},
		data() {
			try {
				const { data } = this.notifications.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.notifications.getNotifications().then(() => {
			// Wait a little to mark notifications as seen
			setTimeout(() => {
				this.markNotifications.markNotificationsAsSeen();
			}, 1500);
		});
	},
	methods: {
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.notifications.getNotifications(options);
		},
	},
};
</script>
