import {
	GET_NOTIFICATIONS, GET_NOTIFICATIONS_INFO, MARK_NOTIFICATIONS_AS_SEEN,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class Notifications {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getNotifications(options) {
		const { method, endpoint } = GET_NOTIFICATIONS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getNotificationsInfo(options) {
		const { method, endpoint } = GET_NOTIFICATIONS_INFO;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	markNotificationsAsSeen() {
		const { method, endpoint } = MARK_NOTIFICATIONS_AS_SEEN;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Notifications;
